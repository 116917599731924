// jquery
import * as jQuery from "jquery";
window.jQuery = window.$ = jQuery;

// handlebars
import "handlebars";

// bootstrap
import "bootstrap";
import "bootstrap/../../css/bootstrap.css"

import "alpaca";
import "alpaca/../alpaca.css"

// import "../lib/jstree/jstree.min";
import "jstree";
import "jstree-bootstrap-theme/proton/style.css";

// //import "../lib/spin/spin";
import "spin";
import "jquery.spin";

// lightbox2
import "lightbox2/js/lightbox";
import "lightbox2/css/lightbox.css";

// yamm (megamenu)
import "yamm/yamm.css";

// font awesome
//import "fontawesome/css/all.css";
import "fontawesome/css/fontawesome.css";
import "fontawesome/css/brands.css";
import "fontawesome/css/solid.css";
import "fontawesome/css/duotone.css";
import "fontawesome/css/v4-shims.css";

////////////////////////////

// APP code
import "./scss/style.scss";

//
// // Beautify (used by EditorField)
// import "../lib/beautify/beautify";
// import "../lib/beautify/beautify-css";
// import "../lib/beautify/beautify-html";
//
// import "../lib/ytplayer/jquery.mb.YTPlayer_modifed";
// import "../lib/venobox/venobox";
//
// import "../lib/simple-text-rotator/jquery.simple-text-rotator";
// import "../lib/google-code-prettify/prettify.min";
// import "../lib/js-cookie/js.cookie";
// import "../lib/jquery-smooth-scroll/jquery.smooth-scroll";
// import "../lib/jquery.rwd.imagemaps/jquery-rwd-imagemaps.min";
// import "../lib/jquery-match-height/dist/jquery.matchHeight";
// import "../lib/slick-carousel/slick/slick.min";
//
// import "../lib/videoLightning";
//
// //import "./documentation";
//

